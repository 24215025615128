<template>
  <div>
    <c-card title="공종" class="cardClassDetailForm no-margin">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="선택" 
            icon="check"
            @btnClicked="select" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-12">
          <q-tree
            ref="process-mst-multi"
            :nodes="processTree"
            tick-strategy="strict"
            node-key="processCd"
            label-key="processName"
            children-key="children"
            no-nodes-label="공종 정보가 없습니다."
            :default-expand-all="true"
            :ticked.sync="checkProcess"
          />
        </div>
      </template>
    </c-card>
  </div>
</template>

<script>
import arrayToTree from 'array-to-tree';
export default {
  name: 'process-mst-multi-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          constructTypeCd: '',
          parentcheckProcess: '',
          processList: [],
          multiple: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
    plantCd: {
      type: String,
    },
  },
  data() {
    return {
      checkProcess: [],
      processTrees: [],
      processList: [],
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    processTree() {
      let val = this.convertTree(this.processTrees);
      return val ? val : [];
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.processTrees = this.popupParam.processList;
      if (this.popupParam.parentcheckProcess) {
        this.checkProcess = this.popupParam.parentcheckProcess.split(',');
      }
    },
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upProcessCd',
        customID: 'processCd',
      });
      return tree;
    },
    select() {
      if (!this.checkProcess || this.checkProcess.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '공종을 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', this.$refs['process-mst-multi'].getTickedNodes());
      }
    },
  }
};
</script>
